import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { ParagraphWrapper } from '../ParagraphWrapper';
import { t } from '../../../domain/services/configService';
import { theme } from '../../../domain/theme';
import { SectionHeader } from '../../responsive/molecules/SectionHeader';
import { getTravelGuideInfo } from '../../../domain/utils/contentUtils/hubPage/travelGuideUtils';
import { InlineSearch } from '../../responsive/organisms/InlineSearch';
import { normalizeDiacritics } from '../../../domain/utils/stringUtils';

const TravelGuideSearchBoxParagraphInner = ({ content, data, colors }) => {
  const { isTravelGuideEarth } = getTravelGuideInfo(content);

  if (!isTravelGuideEarth) {
    return <div />;
  }

  const styles = {
    container: css`
      padding: ${theme.spacing.parse('0 $base 0 $base')};
      margin: ${theme.spacing.parse(
        isTravelGuideEarth ? '$s auto' : '$s auto $base auto'
      )};
      max-width: 84rem;
      width: 100%;
      background: ${colors.background};
    `,
    sectionHeader: css`
      margin: ${theme.spacing.parse('$xl4 0 $xl 0')};
    `
  };

  const inlineSearchResolver = q => {
    const formattedData = data.countryList.data.map(item => {
      const countryName = item.title.replace(`${t('Travel Guide')}: `, '');
      return {
        title: isTravelGuideEarth
          ? `${countryName} | ${item.continent}`
          : countryName,
        url: item.url
      };
    });
    const results = formattedData
      .filter(travelGuide =>
        normalizeDiacritics(travelGuide.title.toLowerCase()).startsWith(
          q?.toLowerCase()
        )
      )
      .map(result => {
        return {
          ...result,
          listLabel: `<strong>${result.title.substring(
            0,
            q.length
          )}</strong>${result.title.substring(q.length)}`
        };
      });
    return results;
  };

  return data.countryList.data.length > 0 ? (
    <div css={styles.container}>
      {isTravelGuideEarth && (
        <div css={styles.sectionHeader}>
          <SectionHeader
            title={t('Discover your next destination')}
            type="yellow-bold"
            color={colors.color}
          />
        </div>
      )}
      <InlineSearch
        resolver={inlineSearchResolver}
        type="small"
        placeholder={t('Search by country')}
      />
    </div>
  ) : (
    <div />
  );
};

TravelGuideSearchBoxParagraphInner.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  colors: PropTypes.objectOf(PropTypes.any).isRequired
};

export const TravelGuideSearchBoxParagraph = ParagraphWrapper(
  TravelGuideSearchBoxParagraphInner
);
